import React            from 'react';
import PropTypes        from 'prop-types';
import Translations     from '../../../utils/Translations';

class TileBadgeContest extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>{Translations.get('BadgeContest')}</span>;
	}
}

TileBadgeContest.propTypes = {
	show: PropTypes.bool.isRequired,
	className: PropTypes.string,
};

TileBadgeContest.defaultProps = {
	show: false,
	className: "badge--normal tile-badge tile-badge-contest",
};

export default TileBadgeContest;
