import React     from 'react';
import PropTypes from 'prop-types';

class TileBadgeVip extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}><span className="icon-actor -icon-person-princess"/>VIP</span>;
	}
}

TileBadgeVip.propTypes = {
	className: PropTypes.string,
	show:      PropTypes.bool.isRequired,
};

TileBadgeVip.defaultProps = {
	className: 'badge--special tile-badge tile-badge-vip h-color-bg',
	show:      false,
};

export default TileBadgeVip;
