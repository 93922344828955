import React            from 'react';
import PropTypes        from 'prop-types';
import Translations     from '../../../utils/Translations';

class TileBadgeBought extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>
			<i className='icon -icon-success-rounded-full h-mr-2'></i>
			{Translations.get('Bought')}
			</span>;
	}
}

TileBadgeBought.propTypes = {
	show: PropTypes.bool.isRequired,
	className: PropTypes.string,
};

TileBadgeBought.defaultProps = {
	show: false,
	className: "badge--normal tile-badge tile-badge-bought",
};

export default TileBadgeBought;
