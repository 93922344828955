import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

class TileBadge18plus extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		const props = {
			className: this.props.className,
		};

		if (typeof this.props.onClick === 'function') {
			props.onClick = this.props.onClick;
		}

		return <span {...props}>{Translations.get('Over18')}</span>;
	}
}

TileBadge18plus.propTypes = {
	show:      PropTypes.bool.isRequired,
	className: PropTypes.string,
	onClick:   PropTypes.func,
};

TileBadge18plus.defaultProps = {
	show:      false,
	className: 'badge--highlight tile-badge tile-badge-18plus',
};

export default TileBadge18plus;
