import React     from 'react';
import PropTypes from 'prop-types';

import Flux      from "../../flux/Flux";
import {VXPay}   from "../../utils/VXPay";

class PreviewPictures extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			firstPictureUrl:  this.props.previewPictureUrls[0] ? this.props.previewPictureUrls[0].pictureUrl : '',
			preloadImage:     this.props.previewPictureUrls[1] ? this.props.previewPictureUrls[1].pictureUrl : '',
			containerWidth:   '100%',
			containerHeight:  '100%',
			isPortraitFormat: this.props.previewPictureUrls[0] ? this.props.previewPictureUrls[0].isPortraitFormat : false,
		};

		this.firstImageRef              = null;
		this.previewInterval            = null;
		this.rotatingPreviewPictureUrls = [];

		this.onMouseEnter        = this.onMouseEnter.bind(this);
		this.onMouseLeave        = this.onMouseLeave.bind(this);
		this.onClickFn           = this.onClickFn.bind(this);
		this.rotatePreviewImages = this.rotatePreviewImages.bind(this);
	}

	componentDidMount() {
		if (this.props.needBuying && !this.props.showInVideoTile) {
			this.onMouseEnter();
		} else {
			this.firstImageRef.addEventListener('mouseenter', this.onMouseEnter);
			this.firstImageRef.addEventListener('mouseleave', this.onMouseLeave);
		}

	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (this.previewInterval) {
			window.clearInterval(this.previewInterval);
			this.previewInterval = null;
		}

		this.setState({
			firstPictureUrl:    newProps.previewPictureUrls[0] ? newProps.previewPictureUrls[0].pictureUrl : '',
			preloadImage:       newProps.previewPictureUrls[1] ? newProps.previewPictureUrls[1].pictureUrl : '',
			isPortraitFormat:   newProps.previewPictureUrls[0] ? newProps.previewPictureUrls[0].isPortraitFormat : false,
		});

		this.rotatingPreviewPictureUrls = [...newProps.previewPictureUrls];

		if (this.props.reactKey !== undefined && newProps.selectedKey === this.props.reactKey) {
			this.onMouseEnter();
		}
	}

	componentWillUnmount() {
		if (!this.props.needBuying) {
			if (this.previewInterval) {
				window.clearInterval(this.previewInterval);
				this.previewInterval = null;
			}
			this.firstImageRef.removeEventListener('mouseenter', this.onMouseEnter);
			this.firstImageRef.removeEventListener('mouseleave', this.onMouseLeave);
		}
	}

	onMouseEnter() {
		this.rotatingPreviewPictureUrls = [...this.props.previewPictureUrls];

		if (this.rotatingPreviewPictureUrls.length > 2) {
			//remove first one, because its already loaded
			this.rotatingPreviewPictureUrls.shift();

			//rotate the first image instantly
			this.rotatePreviewImages();
			this.previewInterval = window.setInterval(function() {
				this.rotatePreviewImages();
			}.bind(this), 1500);
		}
	}

	onMouseLeave() {
		if (this.previewInterval && this.rotatingPreviewPictureUrls.length > 0) {
			window.clearInterval(this.previewInterval);
			this.previewInterval = null;
			this.setState({
				firstPictureUrl:  this.props.previewPictureUrls[0].pictureUrl,
				preloadImage:     this.props.previewPictureUrls[1].pictureUrl,
				isPortraitFormat: this.props.previewPictureUrls[0].isPortraitFormat,
			});
		}

	}

	onClickFn(e) {
		if (!Flux.Guest.isLoggedIn()) {
			VXPay.openSignupOrLogin();
		}
		else if (typeof this.props.onClickFn === 'function') {
			this.props.onClickFn(e);
		}
	}

	rotatePreviewImages() {
		const nextImage    = this.rotatingPreviewPictureUrls.shift();
		const preloadImage = this.rotatingPreviewPictureUrls.shift();

		this.setState({
			firstPictureUrl:  nextImage.pictureUrl,
			preloadImage:     preloadImage.pictureUrl,
			isPortraitFormat: nextImage.isPortraitFormat,
		});

		this.rotatingPreviewPictureUrls.unshift(preloadImage);
		this.rotatingPreviewPictureUrls.push(nextImage);
	}

	render() {
		const style1       = {
			backgroundImage: 'url(' + this.state.firstPictureUrl + ')',
			backgroundSize:  (this.state.isPortraitFormat ? 'contain' : 'cover'),
		};
		const preloadStyle = {
			opacity:    1,
			visibility: 'hidden',
		};

		const containerStyle = {
			width:   this.state.containerWidth,
			height:  this.state.containerHeight,
			display: this.props.displayStyle,
		};

		if (this.props.showInVideoTile) {
			containerStyle.position = 'absolute';
			containerStyle.top      = '0px';
			containerStyle.zIndex   = '1';
		}

		return (
			<div style={containerStyle}
			     className={"gallery__preview-image-container" + (this.props.className ? ' ' + this.props.className : '')}
			     onClick={this.onClickFn}
			>
				<img src={this.state.preloadImage} className="gallery__preview-image" loading={this.props.lazyImageLoading ? 'lazy' : 'eager'} style={preloadStyle} />
				<div className="gallery__preview-image -first -no-max-height" ref={(ref) => {
					this.firstImageRef = ref;
				}} style={style1}
				/>
				{this.props.enablePreviewButton && <button className="gallery__preview-play -click-material" >
					<i className="icon -icon-play-rounded-line" />
				</button>}
			</div>
		);
	}
}

PreviewPictures.propTypes = {
	className:           PropTypes.string,
	displayStyle:        PropTypes.string,
	enablePreviewButton: PropTypes.bool,
	previewPictureUrls:  PropTypes.arrayOf(PropTypes.object).isRequired,
	onClickFn:           PropTypes.func,
	reactKey:            PropTypes.number,
	selectedKey:         PropTypes.number,
	videoDuration:       PropTypes.string,
	showInVideoTile:     PropTypes.bool,
	needBuying:          PropTypes.bool,
	lazyImageLoading:    PropTypes.bool,
};

PreviewPictures.defaultProps = {
	displayStyle:        'block',
	enablePreviewButton: false,
	showInVideoTile:     false,
	needBuying:          false,
	lazyImageLoading:    false,
};

export default PreviewPictures;
