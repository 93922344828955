import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

class TileBadgeNew extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>{Translations.get('BadgeNew')}</span>;
	}
}

TileBadgeNew.propTypes = {
	show: PropTypes.bool,
	className: PropTypes.string,
};

TileBadgeNew.defaultProps = {
	className: "badge--highlight tile-badge tile-badge-new",
	show:      false,
};

export default TileBadgeNew;
