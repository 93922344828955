import React     from 'react';
import PropTypes from 'prop-types';

class TileBadgePremium extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>PREMIUM</span>;
	}
}

TileBadgePremium.propTypes = {
	className: PropTypes.string,
	show:      PropTypes.bool.isRequired,
};

TileBadgePremium.defaultProps = {
	className: 'badge--normal',
	show:      false,
};

export default TileBadgePremium;
